import { AceRecord, AceReplayer, ExternalChange, replay } from "@cs124/monace"
import { BrowseRoles, FullResponse } from "@cs124/person"
import { usePersonable } from "@cs124/personable"
import { Deadline, Response, Submission, terminalOutput, testTestingTerminalOutput } from "@cs124/questioner"
import { Step, TestResults, TestTestResults } from "@cs124/questioner-types"
import {
  COMPLETED_THRESHOLD,
  Metadata,
  NewContent,
  SHAREABLE_INTERVAL,
  Shareable,
  ShareableProgress,
  coordinatesFromShareablePath,
  getWalkthroughDuration,
  pathIsJSP,
} from "@cs124/shareable"
import CloseIcon from "@mui/icons-material/Close"
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption"
import DeleteIcon from "@mui/icons-material/Delete"
import PublishIcon from "@mui/icons-material/Publish"
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import { green, yellow } from "@mui/material/colors"
import assert from "assert"
import gravatar from "gravatar"
import HugeUploader from "huge-uploader"
import moment from "moment"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { IAceEditor } from "react-ace/lib/types"
import { useInView } from "react-intersection-observer"
import { Array } from "runtypes"
import stringHash from "string-hash"
import {
  AceChanges,
  AceOutputChange,
  AceOutputScrollChange,
  AceShowOutputChange,
  AceTrace,
  ExternalSubmit,
} from "~/components/ace/Ace"
import { TOPBAR_OFFSET } from "~/components/layout"
import { useLessons } from "~/components/lessons"
import { A, P } from "~/components/material-ui"
import makeLightDark from "~/components/material-ui/makeLightDark"
import {
  DEVELOPMENT,
  GRAVATAR_OPTIONS,
  MAX_ASSISTANT_CONTRIBUTION_COUNT,
  QUESTIONER_SERVER,
  SHAREABLE_SERVER,
} from "../../constants"
import { Ace } from "../ace"
import { LoginButton } from "../login"
import { DARK_DEFAULT_BACKGROUND_COLOR, LIGHT_DEFAULT_BACKGROUND_COLOR } from "../material-ui/theme"
import { CaptionPlayer } from "./CaptionPlayer"
import { PlayerControls } from "./PlayerControls"
import { RecordingDuration } from "./RecordingDuration"
import { useShareable } from "./ShareableProvider"
import { ShareableRating } from "./ShareableRating"
import { ShareableSharing } from "./ShareableSharing"
import { useSharing } from "./SharingProvider"
import { INSTRUCTOR_WIDTH, MIN_LENGTH, NORMAL_WIDTH, PROGRESS_THICKNESS } from "./constants"

export const ShareableCode: React.FC<
  PropsWithChildren<{
    metadata: string
    staticCode: string
    viewing?: string
    viewOnly?: boolean
    results?: string
    preloads?: string[]
    header?: ReactNode
    noProgress?: boolean
    noMinimum?: boolean
    onlyMe?: boolean
    deadline?: Deadline
    jspPath?: string
  }>
> = ({ viewing, viewOnly, ...props }) => {
  const metadata = useMemo(() => Metadata.check(JSON.parse(props.metadata)), [props.metadata])
  const { path, language, semester } = metadata
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "400px 0px",
  })

  const theme = useTheme()
  const router = useRouter()

  const { status } = useSession()
  const { course, headers, loaded: courseLoaded } = usePersonable()
  const { preview: lessonPreview, walkthroughSeen, setWalkthroughSeen, isLesson, current: lesson } = useLessons()
  const preview = isLesson ? lessonPreview : false
  const { showCaptions, setShowCaptions, changeCaptions, playbackRate, volume } = useShareable()

  const { updateSharing } = useSharing()
  const autoPlay = useRef(false)
  const role = course?.role?.role
  const [progressMap, setProgressMap] = useState<{ [key: string]: ShareableProgress }>({})

  const jspCoordinates = pathIsJSP(path)
    ? coordinatesFromShareablePath(path)
    : pathIsJSP(props.jspPath)
      ? coordinatesFromShareablePath(props.jspPath)
      : undefined
  jspCoordinates && assert(jspCoordinates.language === language)

  const [walkthroughs, setWalkthroughs] = useState<Shareable[] | undefined>(
    props.results && !props.onlyMe && Array(Shareable).check(JSON.parse(props.results))
  )
  const localTrace = useRef<AceTrace | undefined>(undefined)
  const [current, setCurrent] = useState<Shareable | undefined>()

  const [showDescription, setShowDescription] = useState(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [srt, setSrt] = useState<string | undefined>()

  const [loaded, setLoaded] = useState(false)
  const firstRefresh = useRef(false)
  const refresh = useCallback(() => {
    let url = `${SHAREABLE_SERVER}/s/${path}?language=${language}`
    if (semester) {
      url += `&semester=${semester}`
    }
    fetch(url, {
      headers,
      credentials: "include",
    })
      .then(async response => {
        const { results: ws, progresses }: { results: Shareable[]; progresses?: ShareableProgress[] } =
          await response.json()
        if (progresses) {
          setProgressMap(progresses.reduce((r, x) => ({ ...r, [x["id"]]: x }), {}))
        }
        setWalkthroughs(ws.filter(w => (props.onlyMe ? w.email === course?.you?.email : true)))
        setCurrent(current => {
          let newCurrent: Shareable | undefined = undefined
          if (!current && !firstRefresh.current) {
            autoPlay.current = false
            if (!course?.staff) {
              newCurrent = ws[0]
            }
          } else if (viewing) {
            const w = ws.find(({ id }) => id === viewing)
            if (w) {
              autoPlay.current = true
            }
            newCurrent = w
          } else if (current && ws.find(({ id }) => current.id === id)) {
            autoPlay.current = false
            newCurrent = current
          }
          firstRefresh.current = true
          return newCurrent
        })
      })
      .catch(() => {
        setCurrent(undefined)
        setWalkthroughs([])
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [path, headers, language, semester, viewing, course?.you?.email, course?.staff, props.onlyMe])

  useEffect(() => {
    inView && courseLoaded && refresh()
  }, [inView, courseLoaded, refresh])

  useEffect(() => {
    if (!current || current.id === "") {
      setSrt(undefined)
      return
    }
    assert(current.content.type === "walkthrough")
    if (!current.content.transcript) {
      setSrt(undefined)
      return
    }
    fetch(`${SHAREABLE_SERVER}/content/transcript/${current.id}.srt`, {
      headers: { "Content-Type": "application/text" },
    })
      .then(response => response.text())
      .then(text => setSrt(text))
      .catch(() => setSrt(undefined))
  }, [current])

  const newTrace = useCallback(
    (newTrace: AceTrace, course: FullResponse) => {
      if (!course?.you || !course?.isStaff) {
        setCurrent(undefined)
        return
      }

      const duration = getWalkthroughDuration(newTrace.editorTrace)
      if (!props.noMinimum && duration < MIN_LENGTH) {
        setMessage({ error: true, message: `New recordings must be at least ${MIN_LENGTH} seconds` })
        setCurrent(undefined)
        return
      }

      const sharing = Shareable.check({
        type: "content",
        path,
        language,
        id: "",
        duration,
        content: {
          type: "walkthrough",
          transcript: false,
        },
        email: course.you.email,
        name: course.you.name.full,
        created: new Date(),
        instructor: course.staffRole === "instructor",
        disabled: false,
        source: "cs124.org",
      })
      localTrace.current = newTrace
      setCurrent(sharing)
    },
    [path, language, props.noMinimum]
  )

  const [replaying, setReplaying] = useState(false)
  const [output, setOutput] = useState<string | undefined>()
  const [showOutput, setShowOutput] = useState<boolean | undefined>()
  const [resetOutput, setResetOutput] = useState<boolean>(true)
  const [outputPosition, setOutputPosition] = useState<OutputPosition | undefined>()

  const editor = useRef<IAceEditor | undefined>(undefined)
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const audioPlayer = useRef<HTMLAudioElement>(null)
  const recordingStarted = useRef<number | undefined>(undefined)
  const [recording, setRecording] = useState(false)
  const playingTrace = useRef<AceReplayer | undefined>(undefined)

  const savedOutput = useRef<string | undefined>(undefined)
  const currentTrace = useRef<AceRecord[] | undefined>(undefined)
  const currentTraceID = useRef<string | undefined>(undefined)
  const [, setDownloadingTrace] = useState(true)
  const savedDownloadingTrace = useRef(false)
  const startContent = useCallback(async (content: Shareable) => {
    if (!audioPlayer.current || !editor.current || !content || savedDownloadingTrace.current) {
      return
    }
    if (content.id !== "") {
      if (currentTraceID.current !== content.id) {
        setDownloadingTrace(true)
        savedDownloadingTrace.current = true
        try {
          const downloadedTrace = Array(AceRecord).check(
            await fetch(`${SHAREABLE_SERVER}/content/trace/${content.id}.json`).then(r => r.json())
          )
          currentTrace.current = downloadedTrace
          currentTraceID.current = content.id
        } catch (err) {
          currentTrace.current = undefined
          currentTraceID.current = undefined
          return
        } finally {
          setDownloadingTrace(false)
          savedDownloadingTrace.current = false
        }
      }
    } else {
      currentTrace.current = localTrace.current.editorTrace
    }
    setOutput(savedOutput.current ?? "")
    setShowOutput(false)
    setResetOutput(r => !r)
    const startTime = Math.round(audioPlayer.current.currentTime * 1000)
    const speed = audioPlayer.current.playbackRate
    playingTrace.current && playingTrace.current.stop()

    const externalState: { output?: string; outputPosition?: OutputPosition; showOutput?: boolean } = {}
    let seekOutput = ""
    playingTrace.current = replay(editor.current, currentTrace.current, {
      start: startTime,
      speed,
      onExternalChange: (externalChange: ExternalChange) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, timestamp, ...change } = externalChange
        AceChanges.check(change)
        if (AceOutputChange.guard(change)) {
          setOutput(change.output)
          if (change.output !== savedOutput.current) {
            setOutputPosition(undefined)
          }
          savedOutput.current = change.output
        } else if (AceShowOutputChange.guard(change)) {
          setShowOutput(change.state === "open")
        } else if (AceOutputScrollChange.guard(change)) {
          setOutputPosition(change)
        }
      },
      seekReducer: externalChange => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, timestamp, ...change } = externalChange
        AceChanges.check(change)
        if (AceOutputChange.guard(change)) {
          externalState.output = change.output
          if (change.output !== seekOutput) {
            externalState.outputPosition = undefined
          }
          seekOutput = change.output
        } else if (AceShowOutputChange.guard(change)) {
          externalState.showOutput = change.state === "open"
        } else if (AceOutputScrollChange.guard(change)) {
          externalState.outputPosition = change
        }
      },
      seekCompleted: () => {
        setOutput(externalState.output)
        setShowOutput(externalState.showOutput)
        setOutputPosition(externalState.outputPosition)
      },
    })
    setReplaying(true)
  }, [])

  const seekTrace = useCallback(
    (content: Shareable, replaying: boolean) => {
      if (!audioPlayer.current || !editor.current || !content || !currentTrace.current) {
        return
      }
      savedOutput.current = undefined
      if (replaying) {
        startContent(content)
      } else {
        const startTime = Math.round(audioPlayer.current.currentTime * 1000)
        playingTrace.current && playingTrace.current.stop()

        const externalState: { output?: string; outputPosition?: OutputPosition; showOutput?: boolean } = {}
        let seekOutput = ""
        playingTrace.current = replay(editor.current, currentTrace.current, {
          start: startTime,
          seek: true,
          seekReducer: externalChange => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { type, timestamp, ...change } = externalChange
            AceChanges.check(change)
            if (AceOutputChange.guard(change)) {
              externalState.output = change.output
              if (change.output !== seekOutput) {
                externalState.outputPosition = undefined
              }
              seekOutput = change.output
            } else if (AceShowOutputChange.guard(change)) {
              externalState.showOutput = change.state === "open"
            } else if (AceOutputScrollChange.guard(change)) {
              externalState.outputPosition = change
            }
          },
          seekCompleted: () => {
            setOutput(externalState.output)
            setShowOutput(externalState.showOutput)
            setOutputPosition(externalState.outputPosition)
          },
        })
      }
    },
    [startContent]
  )

  const stopTrace = useCallback(() => {
    playingTrace.current && playingTrace.current.stop()
    setShowOutput(undefined)
    setResetOutput(r => !r)
    setReplaying(false)
  }, [])

  useEffect(() => {
    if (!course?.you && replaying) {
      stopTrace()
    }
  }, [course?.you, replaying, stopTrace])

  useEffect(() => {
    return () => {
      stopTrace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!audioPlayer.current) {
      return
    }
    if (!current) {
      audioPlayer.current.pause()
      audioPlayer.current.src = ""
      audioPlayer.current.load()
      return
    }
    assert(current.content.type === "walkthrough")
    if (current.id === "") {
      audioPlayer.current.src = localTrace.current.audioUrl
    } else {
      const extension = audioPlayer.current.canPlayType("audio/ogg") !== "" ? ".ogg" : ".mp4"
      audioPlayer.current.src = `${SHAREABLE_SERVER}/content/audio/${current.id}${extension}`
    }
    audioPlayer.current.load()
    if (current.id === "") {
      audioPlayer.current.play()
    }
  }, [current])

  const deleteTrace = useCallback(
    (id: string) => {
      fetch(`${SHAREABLE_SERVER}/s/${id}`, {
        method: "DELETE",
        headers,
        credentials: "include",
      }).then(response => {
        response.status !== 200 && console.error(response)
        setReplaying(false)
        refresh()
      })
    },
    [headers, refresh]
  )
  const [uploading, setUploading] = useState(false)
  const [message, setMessage] = useState<{ error: boolean; message: string; sticky?: boolean }>(undefined)

  const uploadTrace = useCallback(
    (current: Shareable & { editing?: boolean }) => {
      if (!current) {
        return
      }
      const upload = async () => {
        const response = await fetch(`${SHAREABLE_SERVER}/s/`, {
          method: "POST",
          headers,
          credentials: "include",
          body: JSON.stringify(
            NewContent.check({
              path: current.path,
              language: current.language,
              content: {
                type: "walkthrough",
                trace: localTrace.current.editorTrace,
              },
            })
          ),
        })
        if (response.status !== 200) {
          setUploading(false)
          setMessage({ error: true, message: "Uploading trace failed" })
          return
        }

        const content = await response.json()
        const { id } = content

        const blob = await fetch(localTrace.current.audioUrl).then(r => r.blob())
        const file = new File([blob], "audio")
        const uploadHeaders = { ...headers }
        delete uploadHeaders["Content-Type"]
        const uploader = new HugeUploader({
          endpoint: `${SHAREABLE_SERVER}/u/${id}`,
          file,
          headers: uploadHeaders,
          retries: 32,
          delayBeforeRetry: 1,
          chunkSize: DEVELOPMENT ? 1 : 10,
        })

        uploader.on("finish", () => {
          setCurrent(undefined)
          setMessage({
            error: false,
            message: "Upload succeeded! Processing audio. (It's safe to leave the page.)",
            sticky: true,
          })
          setUploading(false)

          fetch(`${SHAREABLE_SERVER}/u/${id}`)
            .then(async response => {
              if (response.ok) {
                setMessage({ error: false, message: "Audio processing succeeded!" })
                refresh()
                updateSharing()
              } else {
                setMessage({ error: true, message: `Audio processing failed: ${response.text}` })
              }
            })
            .catch(err => {
              setMessage({ error: true, message: `Audio processing failed: ${err}` })
            })
        })

        uploader.on("error", err => {
          setMessage({ error: true, message: `Upload failed: ${err.detail}` })
          setUploading(false)
        })
      }

      setUploading(true)
      setMessage(undefined)
      upload()
    },
    [refresh, headers, updateSharing]
  )

  useEffect(() => {
    if (!current || !audioPlayer.current || !autoPlay.current) {
      return
    }
    setWalkthroughSeen(true)
    audioPlayer.current.pause()
    audioPlayer.current.load()
    audioPlayer.current.play()
  }, [current, setWalkthroughSeen])

  useEffect(() => {
    srt && current && changeCaptions(srt, current.name)
  }, [changeCaptions, srt, current])

  useEffect(() => {
    if (!current) {
      localTrace.current = undefined
    }
  }, [current])

  const _editing = useRef<boolean>(false)
  const editing = useMemo(() => {
    const isEditing = current && current.id === ""
    _editing.current = isEditing
    return isEditing
  }, [current])

  const canDelete =
    current &&
    course &&
    (role === "instructor" || role === "head" || role === "headta" || current.email === course.you?.email)

  const watchedTimes = useRef<{ [key: string]: { [key: number]: boolean } }>({})
  const reported = useRef<{ [key: string]: boolean }>({})
  const [watched, setWatched] = useState<{ [key: string]: boolean }>({})

  const traceID = current?.id
  const shouldReport = course?.you && traceID && replaying

  useEffect(() => {
    if (!shouldReport) {
      return
    }
    if (!(traceID in watchedTimes.current)) {
      watchedTimes.current[traceID] = {}
    }

    const reportInterval = (SHAREABLE_INTERVAL / 2 / playbackRate) * 1000

    const reportTimer = setInterval(() => {
      const currentTime = audioPlayer.current?.currentTime
      const duration = audioPlayer.current?.duration

      const timeIndex = Math.floor(currentTime / SHAREABLE_INTERVAL)
      watchedTimes.current[traceID][timeIndex] = true

      const totalTimes = Math.ceil(duration / SHAREABLE_INTERVAL)
      const totalWatched = Object.keys(watchedTimes.current[traceID]).length

      if (totalWatched > totalTimes * COMPLETED_THRESHOLD) {
        setWatched(w => {
          w[traceID] = true
          return w
        })
        !reported.current[traceID] &&
          fetch(`${SHAREABLE_SERVER}/completed/${traceID}`, {
            method: "PUT",
            headers,
            credentials: "include",
          })
            .then(() => {
              reported.current[traceID] = true
            })
            .catch(err => {
              console.warn(`PUT /completed/${traceID} error: ${err}`)
            })
      }
      fetch(`${SHAREABLE_SERVER}/watched/`, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ id: traceID, timestamp: currentTime, duration, playbackRate }),
      })
        .then(async r => {
          const { progress }: { progress?: ShareableProgress } = await r.json()
          if (progress) {
            setProgressMap(c => ({ ...c, [progress.id]: progress }))
          }
        })
        .catch(err => {
          console.warn(`POST /watched/ error: ${err}`)
        })
    }, reportInterval)

    return () => {
      clearInterval(reportTimer)
    }
  }, [shouldReport, traceID, headers, playbackRate])

  useEffect(() => {
    if (!replaying || !audioPlayer.current) {
      return
    }
    const timer = setInterval(() => {
      if (audioPlayer.current?.duration === 0 || audioPlayer.current?.paused) {
        stopTrace()
      }
    }, 1024)
    return () => {
      clearInterval(timer)
    }
  }, [replaying, stopTrace])

  const color = makeLightDark({ color: "dimgrey" }, { color: "silver" })
  const currentAvatar = current && (
    <Box sx={{ position: "relative", marginLeft: 1 }}>
      <Avatar
        sx={{ width: 24, height: 24, marginRight: 1 }}
        src={gravatar.url(current.email, {
          s: "24",
          ...GRAVATAR_OPTIONS,
        })}
      />
      {replaying && (
        <CircularProgress sx={{ position: "absolute", top: 0, left: 0, color }} disableShrink size={theme.spacing(3)} />
      )}
    </Box>
  )

  const id = `sa_${path.replaceAll("/", "_")}`
  const canRate = current?.content && ((course?.isStaff && !preview) || watched[current.id]) && !viewOnly

  const canView =
    metadata.open ||
    (course?.you && (course?.isStaff || pathIsJSP(path) || moment(metadata.available).isBefore(moment())))
  let warning: ReactElement
  if (!canView) {
    warning = (
      <Box sx={restrictedSX}>
        <Alert severity="error">
          <AlertTitle>Content Restricted to Current CS 124 Students</AlertTitle>
          <P>
            A publicly-accessible version of this content is available at{" "}
            <A href="https://learncs.online">learncs.online</A>.
          </P>
          {status === "unauthenticated" && <LoginButton sx={{ marginTop: 1 }} />}
        </Alert>
      </Box>
    )
  }

  const previousSubmission = useRef(0)
  const showWarnings = lesson.jsp?.quality?.warn

  const jspSubmit: ExternalSubmit = useCallback(
    async (contents, setOutput, setShowOutput, setRunning) => {
      setRunning(true)

      const hash = stringHash(contents)
      if (previousSubmission.current === hash) {
        setRunning(false)
        setShowOutput(true)
        return
      }

      fetch(`${QUESTIONER_SERVER}/`, {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(Submission.check({ ...jspCoordinates, contents })),
      })
        .then(response => response.json())
        .then(_response => {
          const response = Response.check(_response)

          let output, retry
          try {
            const treatAsErrors = Array(Step).check(
              Object.keys(props.deadline?.points ?? {}).filter(
                aspect => props.deadline.points[aspect] === props.deadline.points.total * -1 && Step.guard(aspect)
              )
            )

            const results =
              jspCoordinates.submissionType === "SOLVE"
                ? terminalOutput(TestResults.check(response.testingResults), contents, {
                    treatAsErrors,
                    ...(showWarnings && { showWarnings, warningOrder: showWarnings }),
                    showWithTestResults: ["recursion"],
                  })
                : testTestingTerminalOutput(TestTestResults.check(response.testingResults), contents, {
                    treatAsErrors,
                  })
            output = results.output
            retry = results.retry
          } catch (err) {
            output = `Error parsing output. Try reloading the page.`
            retry = true
          }

          if (!retry) {
            previousSubmission.current = hash
          } else {
            previousSubmission.current = 0
          }

          if (DEVELOPMENT && response.cached) {
            setOutput(`${output}\n(Cached)`)
          } else {
            setOutput(output)
          }

          setShowOutput(true)
          setRunning(false)
        })
        .catch(err => {
          console.error(err)
          setRunning(false)
        })
    },
    [jspCoordinates, props.deadline?.points, headers, showWarnings]
  )

  const seenStaff = useMemo(() => {
    if (!loaded || !walkthroughs || !progressMap) {
      return true
    }
    if (course?.isStaff && !DEVELOPMENT) {
      return true
    }
    let sawStaff = false
    let completedAny = false
    for (const walkthrough of walkthroughs) {
      if (!progressMap[walkthrough.id]) {
        continue
      }
      if (walkthrough.instructor) {
        sawStaff = true
      }
      if (walkthrough.instructor && progressMap[walkthrough.id].completed) {
        return true
      }
      if (progressMap[walkthrough.id].completed) {
        completedAny = true
      }
    }
    return sawStaff ? false : completedAny
  }, [loaded, walkthroughs, progressMap, course?.isStaff])

  useEffect(() => {
    if (!router) {
      return
    }
    const warningText = "You have an unsaved walkthrough. Are you sure you want to leave this page?"
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!_editing.current) {
        return
      }
      e.preventDefault()
      return (e.returnValue = warningText)
    }
    const handleBrowseAway = () => {
      if (!_editing.current) {
        return
      }
      if (window.confirm(warningText)) {
        return
      }
      router.events.emit("routeChangeError")
      throw "routeChange aborted"
    }
    window.addEventListener("beforeunload", handleWindowClose)
    router.events.on("routeChangeStart", handleBrowseAway)
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose)
      router.events.off("routeChangeStart", handleBrowseAway)
    }
  }, [router])

  if (jspCoordinates && (!walkthroughs || walkthroughs.length === 0) && !(course?.isStaff || viewOnly)) {
    return <Box ref={ref} />
  }

  const canRecord =
    course?.isStaff &&
    role &&
    (role !== "assistant" || (walkthroughs && walkthroughs.length <= MAX_ASSISTANT_CONTRIBUTION_COUNT)) &&
    !viewing &&
    !viewOnly &&
    !process.env.DISABLE_RECORDING &&
    current === undefined

  const missingBorder = loaded && walkthroughs && walkthroughs.length === 0 && course?.isStaff
  const canShowDescription = course?.isStaff && !preview && !viewing && !viewOnly

  if (missingBorder && preview) {
    return null
  }

  return (
    <Box sx={{ position: "relative" }}>
      {warning && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            opacity: 0.95,
            ...restrictedSX,
            zIndex: 100,
            ...makeLightDark(
              {
                backgroundColor: LIGHT_DEFAULT_BACKGROUND_COLOR,
              },
              {
                backgroundColor: DARK_DEFAULT_BACKGROUND_COLOR,
              }
            ),
          }}
        >
          {warning}
        </Box>
      )}
      {props.header && props.header}
      <Box id={id} sx={{ position: "relative", top: -1 * TOPBAR_OFFSET }} />
      <Paper
        ref={ref}
        elevation={2}
        sx={{
          padding: 1,
          marginBottom: 2,
          border: "4px solid transparent",
          ...makeLightDark(
            {
              ...(missingBorder
                ? { border: "4px solid LightSalmon" }
                : !props.noProgress &&
                  !seenStaff && {
                    borderLeft: "4px solid LightSalmon",
                  }),
            },
            {
              ...(missingBorder
                ? { border: "4px solid IndianRed" }
                : !props.noProgress &&
                  !seenStaff && {
                    borderLeft: "4px solid IndianRed",
                  }),
            }
          ),
        }}
      >
        <Box sx={{ marginBottom: 0 }}>
          <Ace
            snippet
            jspSubmit={jspCoordinates && jspSubmit}
            noMace={viewing !== undefined || viewOnly}
            id={`${language}/${path}`}
            mode={language}
            record={canRecord}
            onRecordStart={() => {
              recordingStarted.current = new Date().valueOf()
              setRecording(true)
            }}
            onRecordComplete={t => {
              recordingStarted.current = undefined
              newTrace(t, course)
              audioPlayer.current?.play()
              setRecording(false)
            }}
            output={output}
            showOutput={showOutput}
            outputPosition={replaying && outputPosition}
            onLoad={e => {
              editor.current = e
            }}
            replaying={replaying}
            resetOutput={resetOutput}
            code={metadata.code}
            staticCode={props.staticCode}
            wrapperStyle={{ marginBottom: 0 }}
          />
          {srt && showCaptions && audioPlayer.current && current && (
            <CaptionPlayer
              name={current.name && current.name}
              srt={srt}
              audioPlayer={audioPlayer.current}
              url={`/content/transcript/${current.id}.srt`}
            />
          )}
          <Box
            sx={{
              height: 36,
              display: "flex",
              alignItems: "center",
              marginTop: 1,
              width: "100%",
              visibility: current ? "visible" : "hidden",
              position: "relative",
            }}
          >
            {!editing && current && current.name ? (
              <Tooltip title={`Playing ${current.name}'s Walkthrough`}>{currentAvatar}</Tooltip>
            ) : (
              currentAvatar
            )}
            {!viewing && !viewOnly && !editing && course?.isStaff && (
              <IconButton
                sx={{
                  color,
                }}
                size="small"
                disableFocusRipple
                disableTouchRipple
                onClick={() => {
                  replaying && stopTrace()
                  if (audioPlayer.current) {
                    audioPlayer.current.pause()
                    audioPlayer.current.removeAttribute("src")
                    while (audioPlayer.current.firstChild) {
                      audioPlayer.current.removeChild(audioPlayer.current.firstChild)
                    }
                    audioPlayer.current.load()
                  }
                  setCurrent(undefined)
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            {srt && (
              <ToggleButton
                value={showCaptions}
                sx={{ padding: "2px", border: 0, color }}
                selected={showCaptions}
                onChange={() => setShowCaptions(!showCaptions)}
              >
                <ClosedCaptionIcon />
              </ToggleButton>
            )}
            {playerLoaded && <PlayerControls player={audioPlayer.current} />}
            <audio
              preload="auto"
              ref={audioPlayer}
              onLoadedData={() => {
                setPlayerLoaded(true)
              }}
              onPlay={() => {
                if (volume === 0 && !showCaptions) {
                  setMessage({ error: true, message: `Unmute audio to play walkthrough` })
                  audioPlayer.current.pause()
                  return
                }
                startContent(current)
              }}
              onEnded={stopTrace}
              onPause={stopTrace}
              onSeeked={() => seekTrace(current, replaying)}
              onRateChange={() => {
                if (replaying) {
                  stopTrace()
                  startContent(current)
                }
              }}
            />
            <Box
              sx={{
                marginLeft: 0,
                marginRight: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {!viewing && !viewOnly && (
                <>
                  {canDelete && !preview && (
                    <IconButton
                      disabled={uploading}
                      sx={{ color }}
                      size="small"
                      onClick={() => {
                        stopTrace()
                        if (current.id === "") {
                          setCurrent(undefined)
                        } else {
                          deleteTrace(current.id)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {editing && (
                    <IconButton
                      disabled={uploading}
                      sx={{ color }}
                      size="small"
                      onClick={() => {
                        stopTrace()
                        uploadTrace(current)
                      }}
                    >
                      <PublishIcon />
                    </IconButton>
                  )}
                  {uploading && (
                    <Box sx={{ flex: 1, color, display: "flex", alignItems: "center" }}>
                      <CircularProgress sx={{ color }} size={28} variant="indeterminate" />
                    </Box>
                  )}
                </>
              )}
            </Box>
            {recording && (
              <Box sx={{ visibility: recording ? "visible" : "hidden", position: "absolute", right: 0 }}>
                <RecordingDuration start={recordingStarted.current} />
              </Box>
            )}
            {canShowDescription && !showDescription && !recording && (
              <Box
                sx={{
                  visibility: canShowDescription && !showDescription && !current ? "visible" : "hidden",
                  position: "absolute",
                  right: 0,
                }}
              >
                <Button onClick={() => setShowDescription(true)}>
                  <Typography variant="caption">Show Description</Typography>
                </Button>
              </Box>
            )}
          </Box>
          {!viewing && !viewOnly && !walkthroughSeen && loaded && (DEVELOPMENT || !course?.isStaff) && (
            <Alert severity="info">
              <AlertTitle>Interactive Walkthrough</AlertTitle>
              <P>Click on an icon below to start!</P>
            </Alert>
          )}
          {!viewing && walkthroughs && walkthroughs.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {walkthroughs.map((walkthrough, i) => {
                  const { name, email, instructor } = walkthrough
                  const size = instructor ? INSTRUCTOR_WIDTH : NORMAL_WIDTH
                  const progress = progressMap[walkthrough.id]
                  let currentProgress = props.noProgress
                    ? 0
                    : progress
                      ? Math.ceil((progress.watched / progress.total) * 100)
                      : 0
                  if (progress && progress.completed && progress.watched + 1 === progress.total) {
                    currentProgress = 100
                  }
                  const avatar = (
                    <Box sx={{ position: "relative" }}>
                      <Avatar
                        sx={{
                          height: size,
                          width: size,
                          border: instructor ? `2px solid ${yellow[600]} !important` : "none",
                        }}
                        src={gravatar.url(email, {
                          s: size,
                          ...GRAVATAR_OPTIONS,
                        })}
                      />
                      <CircularProgress
                        size={size + PROGRESS_THICKNESS * 2}
                        thickness={PROGRESS_THICKNESS}
                        sx={{
                          position: "absolute",
                          left: -PROGRESS_THICKNESS,
                          top: -PROGRESS_THICKNESS,
                          color: `${green[400]}`,
                        }}
                        variant="determinate"
                        value={currentProgress}
                      />
                    </Box>
                  )

                  const visible = current && current.id === walkthrough.id
                  const tooltipTitle = !name
                    ? "Play Walkthrough"
                    : instructor
                      ? `Play Instructor ${name}'s Walkthrough`
                      : `Play ${name}'s Walkthrough`

                  return (
                    <Badge key={i} color="primary" variant="dot" overlap="circular" invisible={!visible}>
                      <IconButton
                        size="small"
                        disabled={editing || recording}
                        onClick={() => {
                          autoPlay.current = true
                          setWalkthroughSeen(true)
                          current && walkthrough.id === current.id && audioPlayer.current
                            ? audioPlayer.current.play()
                            : setCurrent(walkthrough)
                        }}
                      >
                        <Tooltip
                          placement="bottom"
                          sx={{ tooltip: { maxWidth: "none" } }}
                          title={
                            <Box sx={{ textAlign: "center" }}>
                              {tooltipTitle}
                              {progress && !props.noProgress && (
                                <>
                                  <Box component="br" />
                                  {`(${currentProgress}% Completed)`}
                                </>
                              )}
                            </Box>
                          }
                        >
                          {avatar}
                        </Tooltip>
                      </IconButton>
                    </Badge>
                  )
                })}
              </Box>
              {current && canRate && !editing && current.id && <ShareableRating id={current.id} />}
            </Box>
          ) : (
            props.preloads && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 1,
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {props.preloads.map((email, i) => {
                    return (
                      <IconButton key={i} size="small" disabled>
                        <Box sx={{ position: "relative" }}>
                          <Avatar
                            sx={{
                              height: INSTRUCTOR_WIDTH,
                              width: INSTRUCTOR_WIDTH,
                              border: `2px solid ${yellow[600]}`,
                            }}
                            src={gravatar.url(email, {
                              s: INSTRUCTOR_WIDTH,
                              ...GRAVATAR_OPTIONS,
                            })}
                          />
                          <CircularProgress
                            size={INSTRUCTOR_WIDTH + 2}
                            thickness={PROGRESS_THICKNESS}
                            sx={{
                              position: "absolute",
                              left: -PROGRESS_THICKNESS,
                              top: -PROGRESS_THICKNESS,
                              color: `${green[400]}`,
                            }}
                            variant="determinate"
                            value={0}
                          />
                        </Box>
                      </IconButton>
                    )
                  })}
                </Box>
              </Box>
            )
          )}
        </Box>
        {canShowDescription && showDescription && (
          <Box sx={{ marginTop: 2, marginBottom: -2, padding: 1 }} ref={descriptionRef}>
            {props.children && (
              <>
                <Typography paragraph variant="caption">
                  Walkthrough Instructions (Only Visible to Staff)
                </Typography>
                <hr />
                {props.children}
              </>
            )}
          </Box>
        )}
        {course?.isStaff && !BrowseRoles.includes(course?.staffRole) && <ShareableSharing noPaper />}
      </Paper>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={!!message}
        autoHideDuration={message?.sticky ? null : 4000}
        onClose={() => setMessage(undefined)}
      >
        {message && (
          <Alert onClose={() => setMessage(undefined)} severity={message.error ? "error" : "success"}>
            {message.message}
          </Alert>
        )}
      </Snackbar>
    </Box>
  )
}

type OutputPosition = { top: number; left: number; width: number; height: number }
const restrictedSX: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: 1,
}
