import { Box } from "@mui/material"
import React, { CSSProperties } from "react"

export const YTVideo: React.FC<{ id?: string; autoplay?: boolean; style?: CSSProperties }> = ({
  id,
  autoplay,
  style,
}) => {
  return (
    <Box
      component="iframe"
      className="youtube"
      allowFullScreen={true}
      allow="autoplay"
      src={`https://www.youtube-nocookie.com/embed/${id}?autoplay=${autoplay ? "1" : "0"}&rel=0&modestbranding=1`}
      sx={{
        aspectRatio: 16 / 9,
        border: 0,
        width: "100%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        ...style,
      }}
    />
  )
}
