import { Typography } from "@mui/material"
import React from "react"
import { String } from "runtypes"
import { Ace } from "../ace"
import { Code as MUICode } from "../material-ui"

export interface CodeProps {
  code: string
  mode?: string
}
export const Code: React.FC<CodeProps> = props => {
  return <Ace snippet={true} {...props} />
}

export interface PreProps {
  children: React.ReactElement
}
export const Pre: React.FC<PreProps> = ({ children }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const originalType = String.check((children.props as any).originalType)
    if (originalType === "code") {
      return children
    }
  } catch (err) {}
  return <Typography variant="pre">{children}</Typography>
}
export interface InlineCodeProps {
  value: string
}
export const InlineCode: React.FC<InlineCodeProps> = props => {
  return <MUICode>{props.value}</MUICode>
}
