import { usePersonable } from "@cs124/personable"
import { Paper } from "@mui/material"
import getYouTubeID from "get-youtube-id"
import React, { PropsWithChildren } from "react"
import { YTVideo } from "~/components/youtube"
import { useLessons } from "./LessonsProvider"

export const LessonVideo: React.FC<PropsWithChildren<{ id?: string; url?: string }>> = ({ children, ...props }) => {
  const { course } = usePersonable()
  const { preview } = useLessons()

  const id = props.id ?? getYouTubeID(props.url)

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 2,
        paddingBottom: 1,
        marginBottom: 2,
      }}
    >
      <YTVideo id={id} />
      {course?.isStaff && !preview && <>{children}</>}
    </Paper>
  )
}
