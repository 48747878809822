import { Box, Button } from "@mui/material"
import { useSession } from "next-auth/react"
import React, { useEffect, useState } from "react"
import { DEVELOPMENT } from "../../constants"

export const Promo: React.FC = () => {
  const { status } = useSession()

  const [promoSeen, setPromoSeen] = useState(false)

  useEffect(() => {
    setPromoSeen(localStorage.getItem("helpable:promoSeen") ? true : false)
  }, [])

  useEffect(() => {
    status === "authenticated" && promoSeen && localStorage.setItem("helpable:promoSeen", "true")
  }, [status, promoSeen])

  const show = !promoSeen && !DEVELOPMENT

  return (
    show && (
      <>
        <iframe
          className="youtube"
          src={`https://www.youtube-nocookie.com/embed/OlN8q2FP12U?rel=0&modestbranding=1`}
          style={{ width: "100%" }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <Box sx={{ flex: 1, maxWidth: 640 }}>
            {status === "authenticated" && (
              <Button variant="outlined" onClick={() => setPromoSeen(true)}>
                Hide
              </Button>
            )}
          </Box>
        </Box>
      </>
    )
  )
}
