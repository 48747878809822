import { Schedulable, ScheduleKinds } from "@cs124/api"
import { usePersonable } from "@cs124/personable"
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import range from "lodash/range"
import moment from "moment-timezone"
import { useRouter } from "next/router"
import React, { useEffect, useMemo, useState } from "react"
import { DAY_MAP, MAP_DAY, useScheduleKind } from "."
import { P } from "../material-ui"

const JavaChip: React.FC = () => {
  return <Chip size="small" label={"Java"} color="java" sx={{ fontSize: "0.8em", width: 56 }} />
}

const KotlinChip: React.FC = () => {
  return <Chip size="small" label={"Kotlin"} color="kotlin" sx={{ fontSize: "0.8em", width: 56 }} />
}

const ListSchedulable: React.FC<{ schedulable: Schedulable | undefined; language: string }> = ({
  schedulable,
  language,
}) => {
  if (!schedulable) {
    return null
  }
  if (language === "java") {
    return (
      <Badge badgeContent={schedulable.java} color="secondary" overlap="rectangular">
        <JavaChip />
      </Badge>
    )
  } else if (language === "kotlin") {
    return (
      <Badge badgeContent={schedulable.kotlin} color="secondary" overlap="rectangular">
        <KotlinChip />
      </Badge>
    )
  } else {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ height: "2em" }}>
          {schedulable.kotlin > 0 && (
            <Badge badgeContent={schedulable.kotlin} color="secondary" overlap="rectangular">
              <KotlinChip />
            </Badge>
          )}
        </Box>
        <Box sx={{ height: "2em" }}>
          {schedulable.java > 0 && (
            <Badge badgeContent={schedulable.java} color="secondary" overlap="rectangular">
              <JavaChip />
            </Badge>
          )}
        </Box>
      </Box>
    )
  }
}

export const ShowSchedule: React.FC<{ kind: ScheduleKinds; language?: string; emptyDays?: boolean }> = props => {
  const { all } = useScheduleKind(props.kind)
  const { course } = usePersonable()
  const [counter, setCounter] = useState(0)

  const language = props.language || course?.isStaff ? "either" : course?.language || "either"

  const { startDay, endDay, startHour, endHour, currentDay, currentHour, byTime } = useMemo(() => {
    const now = new Date().valueOf()
    const currentOffset = now - moment(now).tz("America/Chicago").startOf("week").valueOf()

    let startDay = 7
    let endDay = -1
    let startHour = 24
    let endHour = -1
    let currentDay = -1
    let currentHour = -1
    const byTime = {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
    }

    all.forEach(schedulable => {
      const { day, hour, java, kotlin, start, end } = schedulable
      const d = MAP_DAY[day]
      const h = parseInt(hour)

      if (start < currentOffset && currentOffset < end) {
        currentDay = d
        currentHour = h
      }
      let empty = false
      if (language === "both" && (java === 0 || kotlin === 0)) {
        empty = true
      } else if (language === "either" && java === 0 && kotlin === 0) {
        empty = true
      } else if (language === "java" && java === 0) {
        empty = true
      } else if (language === "kotlin" && kotlin === 0) {
        empty = true
      }
      if (empty && !props.emptyDays) {
        return
      }
      if (d < startDay) {
        startDay = d
      }
      if (d > endDay) {
        endDay = d
      }
      if (empty) {
        return
      }
      if (h < startHour) {
        startHour = h
      }
      if (h > endHour) {
        endHour = h
      }
      byTime[d][h] = schedulable
    })
    return { startDay, endDay, startHour, endHour, byTime, currentDay, currentHour }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all, language, counter])

  useEffect(() => {
    const timer = setInterval(
      () => {
        setCounter(c => c + 1)
      },
      1024 * 60 * 5
    )
    return () => {
      clearInterval(timer)
    }
  }, [])

  const router = useRouter()

  if (startDay > endDay || startHour > endHour) {
    return (
      <Alert severity="info">
        <AlertTitle>Calendar Is Empty</AlertTitle>
        <P>No events have been scheduled on this calendar. Yet!</P>
      </Alert>
    )
  }

  return (
    <TableContainer sx={{ marginBottom: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell key={-1} />
            {range(startDay, endDay + 1).map((day, i) => (
              <TableCell align="center" key={i}>
                <Typography variant="strong">{DAY_MAP[day].substring(0, 3)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {range(startHour, endHour + 1).map((hour, i) => (
            <TableRow key={i}>
              <TableCell align="right" key={-1}>
                {hour < 12 ? `${hour}AM` : hour == 12 ? `12PM` : `${hour - 12}PM`}
              </TableCell>
              {range(startDay, endDay + 1).map((day, j) => (
                <TableCell
                  align="center"
                  key={j}
                  sx={{
                    backgroundColor: day === currentDay && hour === currentHour ? "LightGreen" : "inherit",
                    cursor: "pointer",
                  }}
                  onClick={() => router.push("/tutoring/")}
                >
                  <ListSchedulable schedulable={byTime[day][hour]} language={language} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
